import Vuex from 'vuex'

export default new Vuex.Store( {
    state: {
        // rootDataURL: "https://localhost:7293/",
       // rootDataURL: "https://newcapital.teamdms.dev/api/",
        rootDataURL: "https://newcapitalfinancial.com/api/",
        cloudflare: "0x4AAAAAAAIoEXeVt7ZTyzpT"
       // cloudflare: "1x00000000000000000000AA"
    }
})